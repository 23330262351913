<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，输入框和添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button
            type="primary"
            @click="showAddTypeDialog"
          >+ 新增广告</el-button>
        </el-col>
      </el-row>
      <!-- 第二行，筛选 -->
      <div class="secondrow">
        <!-- 根据商品名查询 -->

        <div class="input">
          使用对象：
          <el-select
            v-model="queryInfo.showLocation"
            clearable
            @clear="getBannerList"
            placeholder="请选择使用对象"
            @change="getBannerList"
          >
            <el-option
              v-for="item in targetList"
              :key="item.id"
              :label="item.label"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 内容主体 -->
      <el-table
        :data="bannerList"
        stripe
        border
      >
        <!-- 第一列id -->
        <el-table-column
          label="id"
          width="100"
          aligin="center"
          prop="id"
        >
        </el-table-column>
        <!-- 第二列名称 -->
        <el-table-column
          label="广告名称"
          aligin="center"
          prop="name"
        >
        </el-table-column>
        <!-- 第三列图片 -->
        <el-table-column
          label="广告图片"
          aligin="center"
        >
          <template slot-scope="scope">
            <el-popover
              trigger="hover"
              title=""
              placement="bottom"
              v-if="scope.row.imageUrl"
            >
              <img
                v-if="scope.row.newImageUrl === 'jpeg'||scope.row.newImageUrl ==='jpg'||scope.row.newImageUrl ==='png'||scope.row.newImageUrl === 'bmp'"
                :src="scope.row.imageUrl"
                width="200"
                height="200"
              />
              <img
                v-if="scope.row.newImageUrl === 'jpeg'||scope.row.newImageUrl ==='jpg'||scope.row.newImageUrl ==='png'||scope.row.newImageUrl === 'bmp'"
                slot="reference"
                :src="scope.row.imageUrl"
                style="height: 80px;width: 80px"
              />
              <video
                controls="controls"
                v-if="scope.row.newImageUrl ==='mp4'||scope.row.newImageUrl ==='mp3'||scope.row.newImageUrl ==='wmv'|| scope.row.newImageUrl ==='mpeg'||scope.row.newImageUrl === 'avi'"
                :src="scope.row.imageUrl"
                width="200"
                height="200"
              ></video>
              <video
                v-if="scope.row.newImageUrl ==='mp4'||scope.row.newImageUrl ==='mp3'||scope.row.newImageUrl ==='wmv'|| scope.row.newImageUrl ==='mpeg'||scope.row.newImageUrl === 'avi'"
                slot="reference"
                :src="scope.row.imageUrl"
                style="height: 80px;width: 80px"
              ></video>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 第四列跳转链接 -->
        <el-table-column
          label="跳转链接"
          width="300"
          aligin="center"
          prop="route"
        >
        </el-table-column>
        <!-- 第五列使用对象 -->
        <el-table-column
          label="使用对象"
          aligin="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.showLocation === 'home'">线上商城</div>
            <div v-else>商家电子秤</div>
          </template>
        </el-table-column>
        <!-- 第六列展示时间 -->
        <el-table-column
          label="展示时间"
          width="300"
          aligin="center"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.showTimeBegin }} - {{ scope.row.showTimeEnd }}
            </div>
          </template>
        </el-table-column>
        <!-- 第七列排序 -->
        <el-table-column
          label="排序"
          aligin="center"
          prop="sortIndex"
        >
        </el-table-column>
        <!-- 第八列操作 -->
        <el-table-column
          label="操作"
          width="200"
          aligin="center"
        >
          <template slot-scope="scope">
            <!-- 编辑按钮 -->
            <el-button
              size="mini"
              type="primary"
              @click="showEditTypeDialog(scope.row)"
            >修改</el-button>
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeTypeById(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 新增对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="addBannerListDialogVisible"
      width="700px"
      @close="clearAddInfo"
    >
      <el-form
        ref="agriculturalBannerFormRef"
        :rules="agriculturalBannerFormRules"
        :model="agriculturalBannerForm"
        label-width="80px"
      >
        <div class="input2">
          <el-form-item
            label="使用对象"
            prop="showLocation"
          >
            <el-select
              v-model="agriculturalBannerForm.showLocation"
              placeholder="请选择使用对象"
              @change="selectLocation"
            >
              <el-option
                v-for="item in targetList"
                :key="item.id"
                :label="item.label"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="广告名称"
            prop="name"
          >
            <el-input
              v-model="agriculturalBannerForm.name"
              placeholder="请输入广告名称"
              clearable=""
              class="inputName"
            ></el-input>
          </el-form-item>
        </div>
        <p style="font-style:italic;margin-bottom:10px;">{{ toast }}</p>
        <el-form-item
          label="广告图片"
          prop="imageUrl"
        >
          <el-upload
            action="api/v1/file/file"
            :data="this.bizType1"
            :before-upload="beforeUpload"
            :on-success="handlesuccess1"
            :show-file-list="false"
          >
            <video
              controls="controls"
              v-if="this.fileType ==='mp4'||this.fileType ==='mp3'||this.fileType ==='wmv'|| this.fileType ==='mpeg'||this.fileType === 'avi'"
              :src="agriculturalBannerForm.imageUrl"
              :class="[
                agriculturalBannerForm.showLocation == 'home'
                  ? 'avatar1'
                  : 'avatar2'
              ]"
            ></video>
            <img
              v-if="this.fileType === 'jpeg'||this.fileType ==='jpg'||this.fileType ==='png'||this.fileType === 'bmp'"
              :src="agriculturalBannerForm.imageUrl"
              :class="[
                agriculturalBannerForm.showLocation == 'home'
                  ? 'avatar1'
                  : 'avatar2'
              ]"
            />
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="跳转链接"
          prop="route"
        >
          <el-input
            v-model="agriculturalBannerForm.route"
            placeholder="请输入跳转链接"
            clearable=""
          ></el-input>
        </el-form-item>
        <el-form-item
          label="展示时间"
          prop="showTime"
        >
          <el-date-picker
            v-model="showTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['12:00:00']"
            value-format="yyyy-MM-dd hh:mm:ss"
            @change="changeActiveTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="排序"
          prop="route"
        >
          <el-input-number
            v-model="agriculturalBannerForm.sortIndex"
            :min="1"
            :max="10"
            label="排序"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="addBannerListDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 请求入参
      queryInfo: {
        name: '',
        limit: 15,
        pageNo: 1,
        showLocation: 'home' // home商户端，scale电子秤，默认商户端，必填
      },
      // 问题分类列表
      bannerList: [],
      total: 0,
      // 标题
      title: '',
      // 新增还是修改
      submitType: '',
      // 修改的id
      typeId: '',
      // 对话框
      addBannerListDialogVisible: false,
      // 新增类型入参
      agriculturalBannerForm: {
        showLocation: '', // home商户端，scale电子秤
        name: '',
        imageUrl: '',
        route: '',
        showTimeBegin: '',
        showTimeEnd: '',
        sortIndex: 1
      },
      // 上传广告的type
      bizType1: {
        bizType: 'banner'
      },
      // 选择项
      targetList: [
        {
          id: 0,
          name: 'home',
          label: '线上商城'
        },
        {
          id: 1,
          name: 'scale',
          label: '商家电子秤'
        },
        {
          id: 2,
          name: 'queryScreen',
          label: '查询屏'
        }
      ],
      // 展示时间
      showTime: [],
      // 校验
      agriculturalBannerFormRules: {
        showLocation: [
          { required: true, message: '请选择使用对象', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入广告名称', trigger: 'blur' }],
        imageUrl: [
          { required: true, message: '请上传广告图片', trigger: 'blur' }
        ]
      },
      // 提示语
      toast: '',
      fileType: '',
      merchantsList: [],
      imageUrl: ''
    }
  },
  created () {
    // 请求分类
    this.getBannerList()
  },
  methods: {
    // 定义请求分类的方法
    async getBannerList () {
      const { data: res } = await this.$http.get('/agriculturalBanner/list', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('获取广告失败')
      }
      // 赋值
      // this.bannerList = res.data.data
      this.bannerList = res.data.data.map(item => ({
        ...item,
        newImageUrl: item.imageUrl.substring(item.imageUrl.lastIndexOf('.') + 1)
      }))
      this.total = res.data.total
    },
    // 点击新增按钮
    showAddTypeDialog () {
      this.title = '新增广告'
      this.submitType = 'add'
      this.addBannerListDialogVisible = true
    },
    // 编辑
    showEditTypeDialog (row) {
      // console.log(row)
      this.addBannerListDialogVisible = true
      this.agriculturalBannerForm = row
      this.fileType = row.newImageUrl
      this.agriculturalBannerForm.imageUrl = row.imageUrl
      this.title = '修改广告'
      this.submitType = 'edit'
      // 赋值修改的id
      this.typeId = row.id
      // 时间赋值
      this.showTime = [
        this.agriculturalBannerForm.showTimeBegin,
        this.agriculturalBannerForm.showTimeEnd
      ]
    },
    // 提交新增和修改请求
    async submit () {
      this.$refs.agriculturalBannerFormRef.validate(async (valid) => {
        if (!valid) return
        // 可以发起网络请求
        if (this.submitType === 'add') {
          const { data: res } = await this.$http.post(
            'agriculturalBanner',
            this.agriculturalBannerForm
          )
          if (res.code !== 0) {
            return this.$message.error('添加广告失败')
          }
          this.$message.success('添加广告成功')
          this.getBannerList()
          this.addBannerListDialogVisible = false
        } else {
          // 赋值成功后提交
          const { data: res } = await this.$http.put(
            `agriculturalBanner/${this.typeId}`,
            this.agriculturalBannerForm
          )
          if (res.code !== 0) {
            return this.$message.error('修改广告失败')
          }
          this.$message.success('修改广告成功')
          this.getBannerList()
          this.addBannerListDialogVisible = false
        }
      })
    },
    // 关闭对话框
    clearAddInfo () {
      this.agriculturalBannerForm = {}
      this.showTime = []
      this.agriculturalBannerForm.sortIndex = 1
    },
    // 删除
    async removeTypeById (id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该广告，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(`agriculturalBanner/${id}`)
      if (res.code !== 0) {
        return this.$message.error('删除广告失败')
      }
      this.$message.success('删除广告成功')
      this.getBannerList()
    },
    // 跳转问题列表
    goQuestionList (row) {
      this.$router.push({
        path: 'question-list',
        query: { id: row.id, target: row.target }
      })
    },
    beforeUpload (file) {
      console.log(file.type)
      const types = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'video/mp4', 'video/mp3', 'video/wmv', 'video/mpeg', 'video/avi']
      this.isImage = types.includes(file.type)
      // console.log(this.isImage, '33333333')
      if (!this.isImage) {
        this.$message.error('上传文件只能是jpg、jpeg、bmp、png、mp4、mp3、wmv、mpeg、avi格式 ')
        return false
      }
      return true
    },
    // 监听图标上传成功的钩子
    handlesuccess1 (response, file, fileList) {
      this.$forceUpdate()
      this.fileType = response.data.substring(response.data.lastIndexOf('.') + 1)
      // console.log(this.fileType, 'this.fileType')
      this.agriculturalBannerForm.imageUrl = response.data
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getBannerList()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getBannerList()
    },
    // 领取时间
    changeActiveTime () {
      if (this.showTime) {
        // 赋值时间
        this.agriculturalBannerForm.showTimeBegin = this.showTime[0]
        this.agriculturalBannerForm.showTimeEnd = this.showTime[1]
      }
    },
    // 选择
    selectLocation () {
      if (this.agriculturalBannerForm.showLocation === 'home') {
        this.toast = '推荐图片尺寸 700*350px'
      } else {
        this.toast = '推荐图片尺寸 1200*750px'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.secondrow {
  margin: 20px 0;
}
.avatar1 {
  width: 350px;
  height: 170px;
}
.avatar2 {
  width: 300px;
  height: 187px;
}
.input2 {
  display: flex;
  justify-content: space-between;
}
</style>
